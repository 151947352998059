/////////////////////////////////////
// Regular Colors (for fonts etc)  //
/////////////////////////////////////

.h-color-1 {
  color: $g-brand-color-1;
}

.h-color-2 {
  color: $g-brand-color-2;
}

.h-color-3 {
  color: $g-brand-color-3;
}

.h-color-4 {
  color: $g-brand-color-4;
}

////////////////////////////////////////
// Background Colors (for fonts etc)  //
////////////////////////////////////////

.h-bg-color-1 {
  background-color: $g-brand-color-1;
}

.h-bg-color-2 {
  background-color: $g-brand-color-2;
}

.h-bg-color-3 {
  background-color: $g-brand-color-3;
}

.h-bg-color-4 {
  background-color: $g-brand-color-4;
}

.h-bg-color-5 {
  background-color: $g-grey-medium;
}

.h-bg-color-grey-light {
  background-color: $g-grey-light;
}

.h-bg-color-grey-medium {
  background-color: $g-grey-medium;
}

.h-bg-color-grey-dark {
  background-color: $g-grey-dark;
}

.h-bg-color-white {
  background-color: #fff;
}
