.c-app-layout {
  height: 100% !important;
  min-height: 100%;
  overflow-y: scroll;

  &__content {
    background-color: $g-white;
    padding: 15px;
    overflow-y: scroll;
    margin: 15px;
    border-radius: 3px;
  }

  &__header {
    background-color: $g-white;
    height: 65px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-logo {
    padding-left: 25px;
    width: 300px;
  }
  &__header-left-side {
    display: flex;
  }

  &__header-user {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__header-navigation {
    display: flex;
  }

  &__header-navigation-item {
    font-weight: 600;
    font-size: 15px;
    color: #667085;
    padding: 0 5px;

    &:hover {
      color: $g-brand-color-1;
      text-decoration: none;
    }

    &:first-child {
      margin-right: 30px;
    }

    &--active {
      color: $g-brand-color-1;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $g-brand-color-1;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}
