/////////////////////////
// Spacing Widths      //
/////////////////////////

.h-width {
  &--center {
    @include bp-sm {
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }

  &--100 {
    @include bp-sm {
      width: 100%;
    }
  }

  &--90 {
    @include bp-sm {
      width: 90%;
    }
  }

  &--80 {
    @include bp-sm {
      width: 80%;
    }
  }

  &--75 {
    @include bp-sm {
      width: 75%;
    }
  }

  &--70 {
    @include bp-sm {
      width: 70%;
    }
  }

  &--60 {
    @include bp-sm {
      width: 60%;
    }
  }

  &--50 {
    @include bp-sm {
      width: 50%;
    }
  }

  &--40 {
    @include bp-sm {
      width: 40%;
    }
  }

  &--33 {
    @include bp-sm {
      width: 33%;
    }
  }

  &--30 {
    @include bp-sm {
      width: 30%;
    }
  }

  &--20 {
    @include bp-sm {
      width: 20%;
    }
  }

  &--10 {
    @include bp-sm {
      width: 10%;
    }
  }
}
