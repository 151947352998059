///////////////////////////////////////////////////////////////////////
// 11. Animation for WOW. Prevents flash of unfired animation        //
///////////////////////////////////////////////////////////////////////

.h-wow .wow {
  visibility: hidden;
}

.lazyload,
.h-lazyload,
.h-lazyload--fade {
  opacity: 0;
  transition: $g-trans-opacity;

  .lazyloaded &,
  .lazyloaded ~ &,
  .lazyloaded ~ * &,
  &.lazyloaded {
    opacity: 1;
    transition: $g-trans-opacity;
  }
}

.lazyloaded {
  opacity: 1;
  transition: $g-trans-opacity;
}

.h-lazyload--from-bottom {
  transform: translate3d(0, 50px, 0);

  .lazyloaded &,
  .lazyloaded ~ &,
  .lazyloaded ~ * &,
  &.lazyloaded {
    transform: translate3d(0, 0, 0);
    transition: $g-trans-transform;
  }
}
