.ar-notes {
  &__notes-viewer-wrapper {
    padding: 4px;
    margin-bottom: 10px;
    border-radius: 4px;
    background-color: #f0f2f5;
  }
  &__note-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  &__note-info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 5px;
    max-width: 85%;
  }
  &__note {
    max-width: 85%;
    padding: 10px;
    border-radius: 10px;
    background-color: $g-brand-color-1;
    color: $g-white;
    font-size: 14px;
  }

  &__notes-viewer {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
  }
}
