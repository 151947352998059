.ar-sum-chart {
  &__card {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    transition: $g-trans-base;
    cursor: pointer;

    .ant-card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }

    .ant-card-head-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--stats-card {
      display: flex;
      .ant-card-body {
        width: 100%;
        display: flex;
        align-items: center;
        min-width: 100px;
      }
      svg {
        height: 35px;
        width: 35px;
      }
    }
  }

  &__card-header {
    font-size: 20px;
    font-weight: 700;
    padding: 20px;
  }
}
