/////////////////////
// 12. Box Shadows //
/////////////////////

.h-boxshadow--light {
  box-shadow: $g-boxshadow-base-light;
}

.h-boxshadow--medium {
  box-shadow: $g-boxshadow-base-medium;
}

.h-boxshadow--heavy {
  box-shadow: $g-boxshadow-base-heavy;
}
