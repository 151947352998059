//////////////////////
// Positioning      //
//////////////////////

// Table positioning
@mixin table {
  display: table;
  width: 100%;
}

@mixin table-cell {
  display: table-cell;
  vertical-align: middle;
}

//Absolute positioning
@mixin absolute-general($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin absolute-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin absolute-tr {
  position: absolute;
  top: 0;
  right: 0;
}

@mixin absolute-br {
  position: absolute;
  bottom: 0;
  right: 0;
}

@mixin absolute-tl {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin absolute-bl {
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin absolute-center-aligner($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin push-auto {
  margin: {
    left: auto;
    right: auto;
  }
}

// Fixed positions
@mixin fixed-full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin bg-cover {
  background-size: cover;
  background-position: center center;
}

@mixin transitionChild {
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      transition: all 0.4s #{$i * 0.05 + 0.1}s ease-in-out;
    }
  }
}

//////////////////////
// Removing Margins //
//////////////////////

@mixin last-p-no-bm {
  p:last-child {
    margin-bottom: 0;
  }
}

@mixin last-child-no-bm {
  & > :last-child {
    margin-bottom: 0;
  }
}
