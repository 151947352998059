.l-admin {
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 0
  }

  &__tab {
    padding: 20px;
    min-height: 400px;
    border: solid $g-semi-light;
    border-width: 1px 1px 1px 0;
  }

  &__heading {
    font-size: 15px;
    font-weight: 600;
  }

  &__btn {
    color: $g-brand-color-1;
  }

  &__btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 24px;

    &--table {
      margin-top: 0;
      justify-content: center;
      gap: 20px;
    }

    .ant-btn {
      min-width: 100px;
    }
  }

  &__modal {
    .ant-form-item-label {
      padding: 0 0 2px;
      font-weight: 600;
    }

    .ant-modal-body {
      margin-top: 15px;
    }
  }
}