.ant-table-thead > tr > th {
  background: #f1f9f8;
  white-space: normal;
  font-weight: 400;
}

.ant-collapse-icon-position-start {
  color: $g-white;
  background: $g-brand-color-1;
  font-weight: 600;
  transition: $g-trans-base;
}
.ant-collapse-header {
  color: $g-white !important;
}

.ant-collapse-content {
  background: $g-white !important;
}

.ant-input-number {
  width: 100%;
}
.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: $g-brand-color-1;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.9);
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 35px !important;
}

.ant-input-password {
  padding: 0px 11px;
}
