/////////////////////////////////
// Clearfix                    //
/////////////////////////////////

.h-clearfix {
  // @include susy-clearfix;
}

///////////////////////////
// Scroll                //
///////////////////////////

.h-scroll {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.h-scroll-lock {
  overflow: hidden;
}

////////////////////////
// Links              //
////////////////////////

.h-link {
  &--inactive {
    pointer-events: none;
    cursor: default;
    color: $g-grey-light;
    border-color: $g-grey-light;
    user-select: none;
  }
}
