//////////////////////////////
// Overlays					//
//////////////////////////////

// Overlay Base
.h-overlay {
  @include absolute-full;
  @include bg-cover;
}

// Overlays Brand Color 1
.h-overlay--b1-heavy {
  background-color: rgba($g-brand-color-1, 0.9);
}

.h-overlay--b1-medium {
  background-color: rgba($g-brand-color-1, 0.5);
}

.h-overlay--b1-light {
  background-color: rgba($g-brand-color-1, 0.25);
}

.h-overlay--b1-fade {
  background: rgba($g-brand-color-1, 1);
  background: linear-gradient(
    to right,
    rgba($g-brand-color-1, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3689e7', endColorstr='#ffffff', GradientType=1);
}

// Overlays Brand Color 2
.h-overlay--b2-heavy {
  background-color: rgba($g-brand-color-2, 0.9);
}

.h-overlay--b2-medium {
  background-color: rgba($g-brand-color-2, 0.5);
}

.h-overlay--b2-light {
  background-color: rgba($g-brand-color-2, 0.25);
}

.h-overlay--b2-fade {
  background: rgba($g-brand-color-2, 1);
  background: linear-gradient(
    to right,
    rgba($g-brand-color-1, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3689e7', endColorstr='#ffffff', GradientType=1);
}

// Overlays Brand Color 3
.h-overlay--dark-heavy {
  background-color: rgba(0, 0, 0, 0.7);
}

.h-overlay--dark-medium {
  background-color: rgba(0, 0, 0, 0.5);
}

.h-overlay--dark-light {
  background-color: rgba(0, 0, 0, 0.25);
}
