.table-empty-state {
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .empty-state-content {
    text-align: center;
    align-items: center;
    max-width: 500px;
    display: flex;
    flex-direction: column;

    .empty-state-heading {
      font-size: 20px;
      font-family: $g-font-base;
      line-height: 34px;
      color: $g-brand-color-2;
      font-weight: 600;
    }

    .empty-state-subheading {
      font-size: 15px;
      font-family: $g-font-base;
      line-height: 28px;
      color: $g-brand-color-2;
      font-weight: 400;
    }

    .empty-state-action-btn {
      width: max-content;
      margin-top: 20px;
    }
  }
}
