////////////////////////
// Hiding Elements 	  //
////////////////////////
.h-hide {
  visibility: hidden;
}

.h-hide--xs {
  @include bpm-xs {
    display: none;
  }
}

.h-hide--sm {
  @include bpm-sm {
    display: none;
  }
}

.h-hide--md {
  @include bp-md {
    display: none;
  }
}
