//////////////////////////////////////////////////
//                                              //
//  Shame                                       //
//  http://www.alqamabinsadiq.com                 //
//                                              //
//////////////////////////////////////////////////

/**
 * The idea of shame.css is that you have a totally new stylesheet reserved just for your hacky code.
 * The code you have to write to get the release out on time, but the code that makes you ashamed.
 *
 * ----------------------------------------------------------------------------------------------
 *
 * By putting your bodges, hacks and quick-fixes in their own file you do a few things:
 *
 * ----------------------------------------------------------------------------------------------
 *
 * You make them stick out like a sore thumb.
 * You keep your ‘main’ codebase clean.
 * You make developers aware that their hacks are made very visible.
 * You make them easier to isolate and fix.
 *
 * ----------------------------------------------------------------------------
 *
 * Obviously we need some kind of rules and criteria:
 *
 * ----------------------------------------------------------------------------
 *
 * If it’s a hack, it goes in shame.css.
 * Document all hacks fully:
 * What part of the codebase does it relate to?
 * Why was this needed?
 * How does this fix it?
 * How might you fix it properly, given more time?
 * Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
 * Try and clean shame.css up when you have some down time.
 * Even better, get a tech-debt story in which you can dedicate actual sprint time to it.
 *
 */

.wow {
  visibility: hidden;
}

.map__map {
  img {
    max-width: inherit !important;
  }
}

.slick-disabled {
  opacity: 0.4 !important;
  pointer-events: none;
}

i.fa {
  vertical-align: middle;
}

pre.dump {
  display: block;
  width: 100%;
  clear: both;
  color: #000 !important;
  background: #ccc !important;
  text-transform: none !important;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.hamburger {
  padding-left: 0px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  height: 2px;
  border-radius: 0;
}

.hamburger--dark {
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background-color: #000;
    height: 2px;
    border-radius: 0;
  }
}

.h-black {
  fill: #000 !important;
}

body {
  &.loading .c-page-loader {
    display: block;
  }
}

#ajax {
  transition: opacity 300ms ease;
  min-height: 100vh;
}

body.loading #ajax {
  opacity: 0;
}

.modaal-wrapper {
  .modaal-content-container {
    padding: 0px;
    line-height: 0%;
  }
  video {
    width: 100%;
  }
}

.hbspt-form {
  position: relative;
  z-index: 1;
  display: block;
}

.wsp-container ul {
  margin-bottom: 30px;
  a {
    color: $g-brand-color-1;
    &:hover {
      color: $g-brand-color-2;
    }
  }
}

#Layer_1-2 {
  #Layer_3 {
    .clt-20 {
      display: none !important;
    }
  }
}
