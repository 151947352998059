//////////////////////////
// 10. Embed Container  //
//////////////////////////
.h-embed-container {
  width: 100%;
  position: relative;
  padding-bottom: 50.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 1em;
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//.h-embed-hero-container {
//	width: 100%;
//	position: absolute;
//	padding-bottom: 53.25%;
//	padding-top: 30px;
//	height: 100%;
//	overflow: hidden;
//	@include bpm-sm {
//		padding-bottom: 60.25%;
//	}
//	video {
//		position: absolute;
//		top: 0;
//		left: 0;
//		bottom: 0;
//		right: 0;
//		width: 100%;
//	}
//}

.h-embed-hero-container {
  @include absolute-full;
  @include bg-cover;
  video {
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
  }
}
