.ar-details {
  &__filters-wrapper {
    margin-bottom: 20px;
  }
  &__stats-card-num-wrapper {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__stats-card-label-wrapper {
    font-weight: 600;
    font-size: 14px;
    color: #738782;
    text-align: center;
  }
  &__stats-card-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
  }
  &__card {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    border: none;
    width: 100%;
    transition: $g-trans-base;
    cursor: pointer;
    height: 100%;
    justify-content: center;

    &:hover {
      transform: scale(1.05);
    }
    .ant-card-body {
      padding: 15px 10px;
    }
    &--no-padding {
      .ant-card-body {
        padding: 0px;
      }
    }
    &--full-height {
      height: calc(100% - 20px);
    }
    &--stats-card {
      display: flex;
      .ant-card-body {
        width: 100%;
        display: flex;
        align-items: center;
        min-width: 100px;
        justify-content: space-between;
      }
      svg {
        height: 35px;
        width: 35px;
      }
    }
  }

  &__notes-btn {
    display: flex;
    width: 100%;
    justify-content: center;
    color: $g-brand-color-1;
  }

  &__progress-card-values {
    margin-bottom: 5px;
    font-weight: 400;
  }

  .ant-progress-inner,
  .ant-progress-bg {
    border-radius: 0px;
  }
}
